@tailwind base;
@tailwind components;
@tailwind utilities;

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield; /* Для Firefox */
}

.range::-webkit-slider-runnable-track {
    background-color: #f3f3f3; /* серый цвет для неактивной части */
}

.range::-moz-range-track {
    background-color: #f3f3f3; /* серый цвет для Firefox */
}

.custom-hover-shadow:hover {
    box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.4); /* Пример кастомной тени */
}